<template>
    <div class="yhj-container">
        <common-header>{{yhjDetailObj.cardName}}</common-header>
        <ul class="card-list">
            <li class="card-item card-item2">
                <span class="left">
                    <span class="left-title">{{yhjDetailObj.cardName}}</span>
                    <span class="left-desc">{{yhjDetailObj.titleDesc}}</span>
                </span>
                <div class="right">
                    <span class="right-title">{{yhjDetailObj.title}}</span>
                    <span class="right-desc">{{yhjDetailObj.desc1}}</span>
                    <span class="right-desc">{{yhjDetailObj.desc2}}</span>
                </div>
            </li>
        </ul>
        <div class="content" v-html="yhjDetailObj['content']">
        </div>
        <div style="display: none;" class="content">
            <div class="use">使用須知</div>
            <div class="desc">打開支付寶APP首頁</div>
            <div class="step">步驟1 找到卡券</div>
            <div class="img">
                <img src="../../assets/images/gj4.png" alt="">
            </div>
            <div class="step">步驟2 點擊公交碼券</div>
            <div class="img">
                <img src="../../assets/images/gj1.png" alt="">
            </div>
            <div class="step">步驟3 點擊出行</div>
            <div class="img">
                <img src="../../assets/images/gj2.png" alt="">
            </div>
            <div class="step">步驟4 使用公交碼券</div>
            <div  class="step-desc">乘坐公交時立即進行掃碼使用</div>
            <div class="img">
                <img src="../../assets/images/gj3.png" alt="">
            </div>
        </div>
    </div>
</template>
<script>
import CommonHeader from '@/components/header'
import {yhjDetailObj} from '@/constant/constant'
export default {
    data() {
        return {
            yhjDetailObj: {}
        }
    },
    components: {
        CommonHeader
    },
    mounted() {
        this.yhjDetailObj = yhjDetailObj[this.$route.params['id']]
    }
}
</script>
<style lang="scss" scoped>
.yhj-container {
    background: #fff;
    min-height: 100vh;
}
.card-list {
    padding-top: 57px;
    padding-bottom: 18px;
    background: #f2f2f2;
    .card-item {
        margin: 0 auto;
    }
}
.content {
    width: 100%;
    padding: 25px 16px;
    box-sizing: border-box;
    text-align: left;
    font-size: 14px;
    color: #666;
    background: #fff;

    h3 {
        font-size: 16px;

        &.title {
            margin-top: 23px;
        }
    }
}

.card-item {
    background: url('../../assets/images/bg-yhj.png') center center no-repeat;
    background-size: 100%;
}

.step {
    width: 180px;
    height: 28px;
    line-height: 28px;
    background: #40B9F9;
    border-radius: 10px;
    text-align: center;
    color: #fff;
    font-size: 16px;
    margin: 37px auto 15px;
}

.use {
    font-size: 16px;
    color: #000;
}

.desc {
    font-size: 14px;
    color: #999;
}

.img {
    width: 280px;
    margin: 0 auto;
    img {
        width: 100%;
    }
}

.step-desc {
    width: 100%;
    text-align: center;
}
</style>